@import "colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.parent {
    display: flex;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: cover;
        filter: saturate(1.5) blur(4px);
        background-image: url("https://i.ibb.co/C5KK8Td/DJI-20230607-155726-796-1-1.webp");
    }
    .container {
        padding: 0 40px;
        margin: auto 0;
    }
    .loginBox {
        background: $pacome-blue;
        border-radius: 15px;
        padding: 10px 20px 30px;
        filter: saturate(1);
        color: white !important;
        font-family: "Montserrat";
        h3 {
            font-family: "Montserrat" !important;
            color: $pacome-gold !important;
            font-weight: 700;
            font-style: normal !important;
            margin: 0;
        }
        h4 {
            margin: 0px 0 30px;
            font-family: "Montserrat" !important;
            color: white !important;
            font-weight: 700;
            font-style: normal !important;
            font-size: 0.9rem;
            font-style: italic;
        }
        input {
            border-radius: 10px;
            margin: 20px 0;
        }
        img {
            width: 80px;
            margin: 20px 0 0px;
        }

        .fancy-button {
            border: 1px solid $pacome-gold;
            background: none;
            transition: ease-out 0.5s;
            font-family: "Montserrat";
            font-weight: 500;
            position: relative;
            color: white;
            padding: 0.25rem 1.5rem;
            width: auto;
            margin: 0 auto;
            border-radius: 10px;
            font-size: 0.9rem;
            /* Position of the funny stuff */
            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                transition: 0.5s;
                top: -9px;
                left: -9px;
                border-top: 2px solid transparent;
                border-left: 2px solid transparent;
            }

            &::before {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                transition: 0.5s;
                bottom: -9px;
                right: -9px;
                border-bottom: 2px solid transparent;
                border-right: 2px solid transparent;
            }

            /* Font on hover */
            &:hover {
                color: white;
                font-weight: 500;
                background: $pacome-gold;
            }

            &:hover::after,
            &:hover::before {
                width: calc(100% + 18px) !important;
                height: calc(100% + 18px) !important;
                border-color: #222222;
                // border-radius: 15px;
            }

            /* color and size of the funky stuff */
            &:hover::after,
            &:hover::before {
                width: 40px;
                height: 40px;
                border-color: $pacome-gold;
            }
        }
    }
}
